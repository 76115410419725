import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/menu/1/items',
  },
  {
    path: '/menu',
    redirect: '/menu/1/items',
  },
  {
    path: '/menu/:id',
    redirect: to => '/menu/'+to.params.id+'/items',
    component: () => import ('../views/MenuPage.vue'),
    props: true,
    children: [
      {
        path: 'items',
        props: true,
        component: () => import ('../views/MenuItems.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
